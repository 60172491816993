body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: 'Open Sans', sans-serif;
}

.leaflet-container {
  height: auto;
  width: 100%;
  height: 100vh;
}

.map-area {
  flex: 1;
  height: 100%;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.holy-grail header,
.hg-sidebar {
  padding: 20px;
}

.holy-grail header {
  font-weight: bold;
  font-family: 'Titillium Web', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  align-content: center;
}

.holy-grail header,
.holy-grail footer {
  background: #161625;
  color: #6c757d;
}
.hg-sidebar {
  background: #1f1e30;
  color: #6c757d;
}
.holy-grail-content {
  color: #777;
  flex: 1 1;
  display: flex;
  padding: 5px;
}

.holy-grail {
  min-height: 100vh;
}
.holy-grail,
.holy-grail-body {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  background: #161625;
}
.holy-grail-content {
  flex: 1 1 auto;
}
.holy-grail-sidebar-1 {
  order: -1;
}
@media (min-width: 1000px) {
  .holy-grail-body {
    flex-direction: row;
  }

  .hg-sidebar {
    flex: 0 0 260px;
  }

  .leaflet-container {
    height: auto;
  }
}

h4 {
  max-width: 800px;
}

h1,
h4,
h3 {
  margin: 0;
}

h1 {
  display: inline-block;
}

.expand,
.expand:active {
  background: none;
  color: #6c757d;
  border: none;
  font-size: large;
  display: inline-flex;
  outline: none;
}
