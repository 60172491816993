.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.info {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.info h4 {
  margin: 0 0 5px;
  color: #777;
}

.legend {
  text-align: left;
  line-height: 18px;
  color: #555;
}

.legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
}

.description {
  font-weight: 100;
}

h1 {
  font-size: xx-large;
  color: white;
}

h2 {
  font-size: x-large;
  color: white;
}

.updatedTime {
  margin: 0;
}

h3 {
  font-size: x-large;
}

.confirmed {
  color: #ee2c2c;
}

.active {
  color: #007fff;
}

.deaths {
  color: #707070;
}

.recovered {
  color: #49e20e;
}

h6,
h5,
h2 {
  margin: 0;
  padding: 0;
}

.stat-type {
  margin-bottom: 10%;
}

.unit {
  margin: 0;
  padding-bottom: 10%;
  font-size: larger;
}

button {
  font-size: larger;
  font-family: 'Titillium Web', sans-serif;
  border-radius: 6px;
  padding: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #161625;
  color: #a9a9a9;
  border-color: #a9a9a9;
}

.holy-grail footer {
  text-align: left;
  padding: 1%;
  font-size: small;
}
